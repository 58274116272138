// extracted by mini-css-extract-plugin
export var backgroundImg_wrapper = "use-cases-module--backgroundImg_wrapper--e83c3";
export var basketLevelWrapper = "use-cases-module--basketLevelWrapper--24985";
export var basketLevel__button = "use-cases-module--basketLevel__button--abc0e";
export var basketLevel__card = "use-cases-module--basketLevel__card--8aecc";
export var basketLevel__container = "use-cases-module--basketLevel__container--85735";
export var basketLevel__content = "use-cases-module--basketLevel__content--82696";
export var basketLevel__contentWrapper = "use-cases-module--basketLevel__contentWrapper--6b0bb";
export var basketLevel__image = "use-cases-module--basketLevel__image--62b56";
export var basketLevel__title = "use-cases-module--basketLevel__title--3292c";
export var bookMockUp__container = "use-cases-module--bookMockUp__container--299ff";
export var bookMockUp__content = "use-cases-module--bookMockUp__content--12525";
export var bookMockUp__header = "use-cases-module--bookMockUp__header--12bdd";
export var bookMockUp__imgContainer = "use-cases-module--bookMockUp__imgContainer--3ed80";
export var bookMockUp__input = "use-cases-module--bookMockUp__input--7a905";
export var bookMockUp__wrapper = "use-cases-module--bookMockUp__wrapper--6fd3e";
export var businessPlan__contentWrapper = "use-cases-module--businessPlan__contentWrapper--dd7b7";
export var businessPlan__discount = "use-cases-module--businessPlan__discount--59b99";
export var businessPlan__featuresText = "use-cases-module--businessPlan__featuresText--5e68e";
export var businessPlan__featuresWrapper = "use-cases-module--businessPlan__featuresWrapper--1b451";
export var businessPlan__price = "use-cases-module--businessPlan__price--880c7";
export var businessPlans = "use-cases-module--businessPlans--6f39c";
export var businessPlans__button = "use-cases-module--businessPlans__button--77ad2";
export var businessPlans__cards = "use-cases-module--businessPlans__cards--1d09f";
export var businessPlans__description = "use-cases-module--businessPlans__description--cbbfb";
export var businessPlans__title = "use-cases-module--businessPlans__title--d6729";
export var contactUsForm = "use-cases-module--contactUsForm--4286b";
export var contactUsForm__button = "use-cases-module--contactUsForm__button--8c0d7";
export var customerSection = "use-cases-module--customerSection--2c1db";
export var customerSection__content = "use-cases-module--customerSection__content--f70a1";
export var customerSection__description = "use-cases-module--customerSection__description--4607e";
export var customerSection__description2 = "use-cases-module--customerSection__description2--afa77";
export var customerSection__item = "use-cases-module--customerSection__item--39554";
export var customerSection__itemContent = "use-cases-module--customerSection__itemContent--96ab7";
export var customerSection__itemHeader = "use-cases-module--customerSection__itemHeader--654ea";
export var customerSection__title = "use-cases-module--customerSection__title--833a4";
export var dataSecurity__container = "use-cases-module--dataSecurity__container--c638b";
export var dataSecurity__content = "use-cases-module--dataSecurity__content--40130";
export var dataSecurity__header = "use-cases-module--dataSecurity__header--0fb58";
export var dataSecurity__item = "use-cases-module--dataSecurity__item--b47fa";
export var features = "use-cases-module--features--56554";
export var firstJourney__content = "use-cases-module--firstJourney__content--30f83";
export var firstJourney__features = "use-cases-module--firstJourney__features--ee7a6";
export var firstJourney__header = "use-cases-module--firstJourney__header--1bafa";
export var firstJourney__id = "use-cases-module--firstJourney__id--35a01";
export var firstJourney__p = "use-cases-module--firstJourney__p--55475";
export var firstJourney__text = "use-cases-module--firstJourney__text--9f175";
export var firstJourney__wrapper = "use-cases-module--firstJourney__wrapper--c0ac0";
export var firstTime__btn = "use-cases-module--firstTime__btn--32492";
export var firstTime__container = "use-cases-module--firstTime__container--d8571";
export var firstTime__content = "use-cases-module--firstTime__content--2a0f0";
export var firstTime__header = "use-cases-module--firstTime__header--c533b";
export var firstTime__images = "use-cases-module--firstTime__images--909ba";
export var firstTime__info = "use-cases-module--firstTime__info--88509";
export var formSuccessSection = "use-cases-module--formSuccessSection--b8201";
export var formSuccessSection__description = "use-cases-module--formSuccessSection__description--86d89";
export var formSuccessSection__icon = "use-cases-module--formSuccessSection__icon--c727e";
export var formSuccessSection__title = "use-cases-module--formSuccessSection__title--d5f37";
export var gainDeepUnderstandingWrapper = "use-cases-module--gainDeepUnderstandingWrapper--2a127";
export var gainDeepUnderstanding__contentWrapper = "use-cases-module--gainDeepUnderstanding__contentWrapper--9af8e";
export var gainDeepUnderstanding__description = "use-cases-module--gainDeepUnderstanding__description--1ceac";
export var gainDeepUnderstanding__title = "use-cases-module--gainDeepUnderstanding__title--9e099";
export var heroSection__backgroundImg = "use-cases-module--heroSection__backgroundImg--e4ce4";
export var heroSection__backgroundImg_wrapper = "use-cases-module--heroSection__backgroundImg_wrapper--324ca";
export var heroSection__button = "use-cases-module--heroSection__button--3934b";
export var heroSection__button__wrapper = "use-cases-module--heroSection__button__wrapper--f3135";
export var heroSection__content = "use-cases-module--heroSection__content--cc2b3";
export var heroSection__description = "use-cases-module--heroSection__description--f87e0";
export var heroSection__ellipse = "use-cases-module--heroSection__ellipse--6b8cd";
export var heroSection__hero_img_desktop = "use-cases-module--heroSection__hero_img_desktop--a7b39";
export var heroSection__skuWrapper = "use-cases-module--heroSection__skuWrapper--3b399";
export var heroSection__title = "use-cases-module--heroSection__title--3254c";
export var heroSection__wrapper = "use-cases-module--heroSection__wrapper--33326";
export var loyalty__container = "use-cases-module--loyalty__container--a983d";
export var loyalty__header = "use-cases-module--loyalty__header--ed438";
export var loyalty__img = "use-cases-module--loyalty__img--66dc1";
export var loyalty__imgContainer = "use-cases-module--loyalty__imgContainer--77eea";
export var loyalty__itemContent = "use-cases-module--loyalty__itemContent--a998d";
export var marketing = "use-cases-module--marketing--06175";
export var marketing__btn = "use-cases-module--marketing__btn--12846";
export var marketing__content = "use-cases-module--marketing__content--5a386";
export var marketing__description = "use-cases-module--marketing__description--156a1";
export var marketing__header = "use-cases-module--marketing__header--13cc0";
export var marketing__img = "use-cases-module--marketing__img--57834";
export var marketing__wrapper = "use-cases-module--marketing__wrapper--4a0f3";
export var physicalRetailer__container = "use-cases-module--physicalRetailer__container--20f8f";
export var physicalRetailer__item = "use-cases-module--physicalRetailer__item--599f8";
export var posJourney__features = "use-cases-module--posJourney__features--020a2";
export var posJourney__header = "use-cases-module--posJourney__header--5e5ac";
export var posJourney__img = "use-cases-module--posJourney__img--fcfbb";
export var posJourney_container = "use-cases-module--posJourney_container--7cf2e";
export var posTerminals__images = "use-cases-module--posTerminals__images--0d2bc";
export var posTerminals__title = "use-cases-module--posTerminals__title--b401f";
export var posTerminals__wrapper = "use-cases-module--posTerminals__wrapper--43c1d";
export var readyToGetStartedContainer = "use-cases-module--readyToGetStartedContainer--a7751";
export var readyToGetStartedWrapper = "use-cases-module--readyToGetStartedWrapper--e9e18";
export var readyToGetStarted__contentWrapper = "use-cases-module--readyToGetStarted__contentWrapper--be340";
export var readyToGetStarted__description = "use-cases-module--readyToGetStarted__description--6cd59";
export var readyToGetStarted__title = "use-cases-module--readyToGetStarted__title--5c6d7";
export var retailer__img = "use-cases-module--retailer__img--97d1d";
export var retain__btn = "use-cases-module--retain__btn--6da4a";
export var retain__content = "use-cases-module--retain__content--6a251";
export var retain__description2 = "use-cases-module--retain__description2--ed6a1";
export var retain__header = "use-cases-module--retain__header--d26e4";
export var retain__wrapper = "use-cases-module--retain__wrapper--d78e5";
export var sectionContainer = "use-cases-module--sectionContainer--c642d";
export var zealApp__container = "use-cases-module--zealApp__container--f10f0";
export var zealApp__giftImg = "use-cases-module--zealApp__giftImg--bb752";
export var zealApp__img = "use-cases-module--zealApp__img--8fd92";
export var zealApp__productsImg = "use-cases-module--zealApp__productsImg--33842";
export var zealApp__typesImg = "use-cases-module--zealApp__typesImg--d21b2";
export var zealApp__wrapper = "use-cases-module--zealApp__wrapper--42c45";