import * as React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "gatsby-plugin-react-i18next";

// Utils
import { revealVariant } from "../../utils/animation-variants";

// Assets
import CheckMark from "../../images/icons/check-mark.svg";

// Styles
import * as styles from "../../styles/pages/use-cases.module.css";

const BusinessPlanCardContent = ({ features, price, discount = 30 }) => {
  const { t } = useTranslation();
  return (
    <motion.div className={styles.businessPlan__contentWrapper}>
      <motion.div className={styles.businessPlan__price}>
        {t("useCases.businessPlans.price", { price })}
      </motion.div>

      <motion.h2 className={styles.businessPlan__discount}>
        {t("useCases.businessPlans.discount", { discount })}
      </motion.h2>
      {(features || [])?.map((feature) => (
        <motion.div
          key={feature}
          className={styles.businessPlan__featuresWrapper}
        >
          <motion.div variants={revealVariant} className="mt-1">
            <CheckMark />
          </motion.div>

          <motion.p className={styles.businessPlan__featuresText}>
            {feature}
          </motion.p>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default BusinessPlanCardContent;
