export const revealContainerProps = {
    "initial": "offscreen",
    "whileInView": "onscreen",
    "viewport": {
        "once": true,
        "amount": 0.4
    },
}
export const revealContainerWithChildrenProps = {
    ...revealContainerProps,
    "transition": {
        "staggerChildren": 0.3
    },
}
export const revealVariant = {
    offscreen: {
        y: 15,
        opacity: 0,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 1,
        }
    }
}
